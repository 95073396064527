import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { IconButton, Paper, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

type PresignedPostResponse = {
  url: string,
  fields: any 
};

const queryClient = new QueryClient();

const useGetUploadUrlQuery = (enabled: boolean, contentType: string) => {
  return useQuery<{status: string, s3_object: PresignedPostResponse}>('fetchUploadUrl', async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/generate-presigned-url?content-type=${contentType}`);
      if (response.status != 200) {
        throw new Error("Error getting post data.");
      }
      return await response.json();
  }, {enabled, refetchOnWindowFocus: false});
}

const uploadFile = async (presignedPostResponse: PresignedPostResponse, file: File) : Promise<string> => {
  const formData = new FormData();

  for (const key in presignedPostResponse.fields) {
    formData.append(key, presignedPostResponse.fields[key]);
  }

  formData.append("file", file);

  const response = await fetch(presignedPostResponse.url, { 
    method: 'POST',
    body: formData
  });

  return presignedPostResponse.url + presignedPostResponse.fields.key;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainComponent />
    </QueryClientProvider>
  );
}

const MainComponent: React.FC = () => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | undefined>();
  const {isLoading, isError, error, data} = useGetUploadUrlQuery(Boolean(file), file?.type ?? '');
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onFileChanged = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files?.[0];
    console.log(file);

    if (file) {
      setFile(file);
    }
  }

  useEffect(() => {
    if (data && file) {
      const asyncUpload = async () => {
        const url = await uploadFile(data.s3_object, file);
        setUploadedFileUrl(url);
      }
    
      asyncUpload();
    }
  }, [data, file]);

  const uplodaButtonClicked = () => {
    inputFile.current?.click();
  } 

  if (error) {
    return (
      <div>
        Error uploading. Please refresh page and try again.
      </div>
    );
  }

  return (
      <div className="App">
        <header>
          <Typography variant='h3'>
            Image Uploader
          </Typography>
        </header>
        <Paper>
          {!uploadedFileUrl ?
          <>
          Click to upload file.
          <IconButton onClick={uplodaButtonClicked} disabled={isLoading || isError}>
            <FileUploadIcon />
          </IconButton>
          </> :
          <>
          Click to copy file URL to clipboard: 
          <IconButton onClick={() => {navigator.clipboard.writeText(uploadedFileUrl)}}>
            <ContentCopyIcon />
          </IconButton>
          </>}
        </Paper>
        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={onFileChanged} />
      </div>
  );
}

export default App;
